import React from "react";
import styled from "styled-components";
import { H1, LongText } from "./atoms/Text";
import { Flex1 } from "./atoms/Flex";

interface AgbBlockProps {
  title: string;
  text: string;
}
const Root = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 32px;
`;
const Content = styled.div`
  flex: 1;
`;

export const AgbBlock = ({ title, text }: AgbBlockProps) => {
  return (
    <Root>
      <Flex1 disableAtMobile />
      <Content>
        <H1>{title}</H1>
        <LongText>{text}</LongText>
      </Content>
      <Flex1 disableAtMobile />
    </Root>
  );
};
