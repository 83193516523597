import React from "react";
import styled from "styled-components";
import { H1, ClickableText } from "./atoms/Text";

export interface Props {
  items: { title: string; link: string }[];
  children: string;
  textOrientation: "right" | "left";
}

interface FooterBoxDivProps {
  textOrientation: "right" | "left";
}

const FooterBoxDiv = styled.div<FooterBoxDivProps>`
  flex: 1;
  text-align: ${(p) => p.textOrientation};
  flex-direction: column;
  display: flex;
  padding-bottom: 13px;
`;

export const FooterBox = ({ items, children, textOrientation }: Props) => {
  return (
    <FooterBoxDiv textOrientation={textOrientation}>
      <H1>{children}</H1>
      {items.map((one) => {
        return (
          <ClickableText to={one.link} key={one.title + one.link}>
            {one.title}
          </ClickableText>
        );
      })}
    </FooterBoxDiv>
  );
};
