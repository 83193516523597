import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { useTracking } from "react-tracking";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { themeDark, themeWhite } from "./theme";
import { ToggleLight } from "./components/atoms/ToogleLight";
import { Main } from "./components/atoms/Main";
import { Footer } from "./components/Footer";
import { MainPage } from "./pages/Main";
import { AgbPage } from "./pages/Agb";
import { DataPrivacyPage } from "./pages/DataPrivacy";
import { AndroidPage } from "./pages/Android";
import { Header } from "./components/Header";
import { Follow } from "./components/atoms/Follow";
import ScrollToTop from "./components/ScrollToTop";
import { RouterTracking } from "./components/RouterTracking";

const App = () => {
  const [theme, setTheme] = useState(themeDark);
  const [showSun, setShowSun] = useState(true);
  const tracking = useTracking();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <RouterTracking />
        <Main>
          <ToggleLight
            onClick={(showDark) => {
              tracking.trackEvent({
                action: `toogle_light_${showDark ? "dark" : "light"}`,
                type: "click",
              });
              setShowSun(showDark);
              if (showDark) {
                setTheme(themeDark);
              } else {
                setTheme(themeWhite);
              }
            }}
            showSun={showSun}
          />
          <Follow>
            <Header />
            <Switch>
              <Route path="/privacy">
                <DataPrivacyPage />
              </Route>
              <Route path="/agb">
                <AgbPage />
              </Route>
              <Route path="/android">
                <AndroidPage />
              </Route>
              <Route path="/">
                <MainPage />
              </Route>
            </Switch>
          </Follow>
        </Main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
