import React from "react";
import styled from "styled-components";
import { Fade } from "react-reveal";
import { H1, Typography } from "./atoms/Text";
import { Flex1 } from "./atoms/Flex";

export interface Props {
  title: string;
  description: string;
  icon: JSX.Element;
  slideDirection: "left" | "right";
}

const Root = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 32px;
`;

const Content = styled.div`
  text-align: center;
`;
export const ContentBlock = ({
  title,
  description,
  icon,
  slideDirection,
}: Props) => {
  return (
    <Root>
      <Flex1 />

      <Content>
        <Fade
          big
          left={slideDirection === "left"}
          right={slideDirection === "right"}
        >
          {icon}
          <H1>{title}</H1>
          <Typography>{description}</Typography>
        </Fade>
      </Content>
      <Flex1 />
    </Root>
  );
};
