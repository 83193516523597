interface Theme {
  backgroundColor: string;
  foregroundColor: string;
  printBackgroundColor: string;
  printForegroundColor: string;
}

export const themeWhite: Theme = {
  backgroundColor: "#fafcfb",
  foregroundColor: "#000000",
  printBackgroundColor: "#FFFFFF",
  printForegroundColor: "#000000",
};

export const themeDark: Theme = {
  backgroundColor: "#323438",
  foregroundColor: "#e7e8e9",
  printBackgroundColor: "#FFFFFF",
  printForegroundColor: "#000000",
};
