import React from "react";
import styled from "styled-components";
import { FooterBox } from "./FooterBox";
import t from "../config/locale";
import { Flex1 } from "./atoms/Flex";

const FooterDiv = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  width: 100%;
  min-height: 100px;
  display: flex;
  border-top: 1px solid ${(props) => props.theme.foregroundColor};
  padding-top: 13px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Footer = () => {
  const footerBoxData = t.to<
    { title: string; items: { title: string; link: "/" }[] }[]
  >("footer.data");
  return (
    <FooterDiv>
      <Flex1 />
      {footerBoxData.map((one) => {
        return (
          <FooterBox key={one.title} textOrientation="left" items={one.items}>
            {one.title}
          </FooterBox>
        );
      })}
    </FooterDiv>
  );
};
