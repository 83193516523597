import styled from "styled-components";

export const Main = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;

  padding: 9px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }

  @media print {
    background-color: ${(props) => props.theme.printBackgroundColor};
    flex-direction: column;
  }
`;
