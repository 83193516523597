import React from "react";
import i18n from "../config/locale";
import { AgbBlock } from "../components/AgbBlock";
import { Follow } from "../components/atoms/Follow";
import { H0 } from "../components/atoms/Text";

export const AgbPage = () => {
  const data = i18n.to<{ title: string; text: string }[]>("agb.data");
  return (
    <Follow>
      <H0>{i18n.t("agb.title")}</H0>
      {data.map((one) => {
        return <AgbBlock key={one.title} title={one.title} text={one.text} />;
      })}
    </Follow>
  );
};
