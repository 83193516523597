import styled from "styled-components";
import { Link } from "react-router-dom";

interface HProps {
  underline?: boolean;
}

export const H1 = styled.span<HProps>`
  && {
    color: ${(props) => props.theme.foregroundColor};
    font-size: 20px;
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    padding-bottom: 6px;
  }
`;

export const H0 = styled.span<HProps>`
  && {
    text-align: center;
    color: ${(props) => props.theme.foregroundColor};
    font-size: 40px;
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    padding-bottom: 6px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

export const Typography = styled.p`
  color: ${(props) => props.theme.foregroundColor};
  font-size: 16px;
  max-width: 300px;
`;

export const LongText = styled.p`
  color: ${(props) => props.theme.foregroundColor};
  font-size: 16px;
`;

export const ClickableText = styled(Link)`
  cursor: pointer;
  color: ${(props) => props.theme.foregroundColor};
  font-size: 16px;
  max-width: 300px;
  padding-bottom: 3px;
  :hover {
    text-decoration: underline;
    color: #ed7d31;
  }
`;
