import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const RouterTracking = () => {
  const tracking = useTracking();
  const location = useLocation();
  const preLoaction = usePrevious(location);
  useEffect(() => {
    if (preLoaction) {
      if (preLoaction.pathname !== location.pathname) {
        tracking.trackEvent({
          action: `navigate_to_${location.pathname}`,
          type: "navigate",
        });
      }
    }
  });
  return null;
};
