import React from "react";
import styled from "styled-components";

import { SvgIcon } from "@material-ui/core";

const MySvgIcon = styled(SvgIcon)`
  && {
    color: ${(props) => props.theme.foregroundColor};
    flex: 1;
    :hover {
      color: #ed7d31;
    }
    font-size: 4.5rem;
    padding: 9px;
  }
`;

export const Security = () => {
  return (
    <MySvgIcon
      viewBox="0 0 62 62"
      attributeName="Icon made by srip from www.flaticon.com"
    >
      <g id="Processor_security" data-name="Processor security">
        <path d="m27 11h2v2h-2z" />
        <path d="m53 35h2v2h-2z" />
        <path d="m35 51h2v2h-2z" />
        <path d="m27 54h2v2h-2z" />
        <path d="m11 31h2v2h-2z" />
        <path d="m35 11h2v2h-2z" />
        <path d="m51 27h2v2h-2z" />
        <path d="m46 17h2v2h-2z" />
        <path d="m16 17h2v2h-2z" />
        <path d="m46 45h2v2h-2z" />
        <path d="m16 45h2v2h-2z" />
        <path d="m31.882 31a2.994 2.994 0 0 0 -.882 5.822v2.178h2v-2.185a2.993 2.993 0 0 0 -1.118-5.815zm1.118 2.961a1 1 0 0 1 -.96 1.039 1 1 0 0 1 -.719-.266 1 1 0 0 1 .639-1.734h.04a1 1 0 0 1 1 .96z" />
        <path d="m37 28.184v-1.184a5 5 0 0 0 -10 0v1.184a3 3 0 0 0 -2 2.816v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-8a3 3 0 0 0 -2-2.816zm-8-1.184a3 3 0 0 1 6 0v1h-6zm8 12a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1-1v-8a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1z" />
        <path d="m59 41a2.951 2.951 0 0 0 -1.285.3l-2.3-2.3h-9.415v-2h5v-2h-5v-2h5.236l5.687-2.844a2.994 2.994 0 1 0 -.923-2.156 2.9 2.9 0 0 0 .037.363l-5.273 2.637h-4.764v-2h3v-2h-3v-2h4.414l3.3-3.3a2.951 2.951 0 0 0 1.286.3 3 3 0 1 0 -3-3 2.951 2.951 0 0 0 .3 1.285l-2.714 2.715h-3.586a5.006 5.006 0 0 0 -5-5v-2.586l5.715-5.714a2.951 2.951 0 0 0 1.285.3 3 3 0 1 0 -3-3 2.951 2.951 0 0 0 .3 1.285l-6.3 6.3v3.415h-2v-3h-2v3h-2v-7.184a3 3 0 1 0 -2 0v7.184h-2v-3h-2v3h-2v-7.414l-4.3-4.3a2.951 2.951 0 0 0 .3-1.286 3 3 0 1 0 -3 3 2.951 2.951 0 0 0 1.285-.3l3.715 3.714v6.586a5.006 5.006 0 0 0 -5 5h-3.586l-2.714-2.715a2.951 2.951 0 0 0 .3-1.285 3 3 0 1 0 -3 3 2.951 2.951 0 0 0 1.285-.3l3.3 3.3h4.415v2h-6.362l-5.238 4.364a2.953 2.953 0 0 0 -1.4-.364 3 3 0 1 0 3 3 2.972 2.972 0 0 0 -.237-1.168l4.599-3.832h5.638v2h-3v2h3v2h-9v2h9v2h-5.535l-4.03 6.044a2.968 2.968 0 0 0 -.435-.044 3.028 3.028 0 1 0 2.21.988l3.325-4.988h4.465a5.006 5.006 0 0 0 5 5v6.586l-3.715 3.714a2.951 2.951 0 0 0 -1.285-.3 3 3 0 1 0 3 3 2.951 2.951 0 0 0 -.3-1.285l4.3-4.3v-7.415h2v6h2v-6h2v8.554l6.074 3.8a3.044 3.044 0 1 0 1.069-1.691l-5.143-3.217v-7.446h2v3h2v-3h2v8h7.184a3 3 0 1 0 0-2h-5.184v-6a5.006 5.006 0 0 0 5-5h8.586l1.714 1.715a2.951 2.951 0 0 0 -.3 1.285 3 3 0 1 0 3-3zm0-14a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-4-9a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-7-12a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-16 1a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-15-2a1 1 0 1 1 1 1 1 1 0 0 1 -1-1zm-9 14a1 1 0 1 1 1 1 1 1 0 0 1 -1-1zm-3 16a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm3 14a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm10 11a1 1 0 1 1 1-1 1 1 0 0 1 -1 1zm22-2a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm9-6a1 1 0 1 1 -1 1 1 1 0 0 1 1-1zm-5-11a3 3 0 0 1 -3 3h-18a3 3 0 0 1 -3-3v-18a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3zm15 4a1 1 0 1 1 1-1 1 1 0 0 1 -1 1z" />
      </g>
    </MySvgIcon>
  );
};

export const Devices = () => {
  return (
    <MySvgIcon
      viewBox="0 0 64 64"
      attributeName="Icon made by phatplus from www.flaticon.com"
    >
      <g>
        <path d="M61.65,14.37a1,1,0,0,0-.77-.37H60V3a1,1,0,0,0-1-1H39a1,1,0,0,0-1,1V14h-.88a1,1,0,0,0-.77.37.989.989,0,0,0-.2.84l.87,4A1,1,0,0,0,38,20H60a1,1,0,0,0,.98-.79l.87-4A.989.989,0,0,0,61.65,14.37ZM40,4H58V14H51a1,1,0,0,0-1,1H48a1,1,0,0,0-1-1H40ZM59.2,18H38.8l-.43-2H46a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1h7.63Z" />
        <path d="M21,42H5a3.009,3.009,0,0,0-3,3V55a3.009,3.009,0,0,0,3,3H8.79l-.67,2.76a.982.982,0,0,0,.18.86,1,1,0,0,0,.79.38h7.82a1,1,0,0,0,.79-.38.982.982,0,0,0,.18-.86L17.21,58H21a3.009,3.009,0,0,0,3-3V45A3.009,3.009,0,0,0,21,42ZM10.36,60l.49-2h4.3l.49,2ZM22,55a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V54H22Zm0-3H4V45a1,1,0,0,1,1-1H21a1,1,0,0,1,1,1Z" />
        <path d="M59,40H51a3.009,3.009,0,0,0-3,3V59a3.009,3.009,0,0,0,3,3h8a3.009,3.009,0,0,0,3-3V43A3.009,3.009,0,0,0,59,40Zm-2,2a1,1,0,0,1-1,1H54a1,1,0,0,1-1-1Zm3,17a1,1,0,0,1-1,1H51a1,1,0,0,1-1-1V43a1,1,0,0,1,1-1,3.009,3.009,0,0,0,3,3h2a3.009,3.009,0,0,0,3-3,1,1,0,0,1,1,1Z" />
        <path d="M17,2H5A3.009,3.009,0,0,0,2,5V21a3.009,3.009,0,0,0,3,3H17a3.009,3.009,0,0,0,3-3V5A3.009,3.009,0,0,0,17,2Zm1,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H17a1,1,0,0,1,1,1Z" />
        <rect x="10" y="18" width="2" height="2" />
        <path d="M42.12,29.1l-2.4-.3,1.49-1.91a.994.994,0,0,0-.09-1.32l-2.69-2.69a1,1,0,0,0-1.32-.09L35.2,24.28l-.3-2.4a1.013,1.013,0,0,0-1-.88H30.1a1.013,1.013,0,0,0-1,.88l-.3,2.4-1.91-1.49a1,1,0,0,0-1.32.09l-2.69,2.69a.994.994,0,0,0-.09,1.32l1.49,1.91-2.4.3a1.013,1.013,0,0,0-.88,1v3.8a1.013,1.013,0,0,0,.88,1l2.4.3-1.49,1.91a.994.994,0,0,0,.09,1.32l2.69,2.69a1,1,0,0,0,1.32.09l1.91-1.49.3,2.4a1.013,1.013,0,0,0,1,.88h3.8a1.013,1.013,0,0,0,1-.88l.3-2.4,1.91,1.49a1,1,0,0,0,1.32-.09l2.69-2.69a.994.994,0,0,0,.09-1.32L39.72,35.2l2.4-.3a1.013,1.013,0,0,0,.88-1V30.1A1.013,1.013,0,0,0,42.12,29.1ZM41,33.02l-2.37.3a.984.984,0,0,0-.82.67c-.09.24-.19.48-.3.71a.979.979,0,0,0,.11,1.05l1.47,1.89-1.45,1.45-1.89-1.47a.979.979,0,0,0-1.05-.11,7.758,7.758,0,0,1-.72.3.989.989,0,0,0-.66.82L33.02,41H30.98l-.3-2.37a.989.989,0,0,0-.66-.82,7.758,7.758,0,0,1-.72-.3.979.979,0,0,0-1.05.11l-1.89,1.47-1.45-1.45,1.47-1.89a.979.979,0,0,0,.11-1.05c-.11-.23-.21-.47-.3-.71a.984.984,0,0,0-.82-.67L23,33.02V30.98l2.37-.3a.984.984,0,0,0,.82-.67c.09-.24.19-.48.3-.71a.979.979,0,0,0-.11-1.05l-1.47-1.89,1.45-1.45,1.89,1.47a.979.979,0,0,0,1.05.11c.23-.11.47-.21.71-.3a.984.984,0,0,0,.67-.82l.3-2.37h2.04l.3,2.37a.984.984,0,0,0,.67.82c.24.09.48.19.71.3a.979.979,0,0,0,1.05-.11l1.89-1.47,1.45,1.45-1.47,1.89a.979.979,0,0,0-.11,1.05c.11.23.21.47.3.71a.984.984,0,0,0,.82.67l2.37.3Z" />
        <path d="M37,32a5,5,0,1,0-5,5A5.006,5.006,0,0,0,37,32Zm-5,3a3,3,0,1,1,3-3A3,3,0,0,1,32,35Z" />
        <path d="M45,30v2h4a1,1,0,0,0,1-1V22H48v8Z" />
        <path d="M11,32v8h2V33h6V31H12A1,1,0,0,0,11,32Z" />
        <path d="M31,19h2V13a1,1,0,0,0-1-1H22v2h9Z" />
        <path d="M33,45H31v8a1,1,0,0,0,1,1H46V52H33Z" />
      </g>
    </MySvgIcon>
  );
};

export const Simple = () => {
  return (
    <MySvgIcon
      viewBox="0 0 512 512"
      attributeName="Icon made by Pixel perfect from www.flaticon.com"
    >
      <g>
        <g>
          <path
            d="M374.016,372c-8.256-30.112-37.536-48.832-65.984-64c-44.224-23.552-86.528-44.384-131.008-68
			c-22.304-11.808-43.904-22.88-60-39.008C97.12,181.12,80.864,149.184,76,120c-1.6-9.6-1.984-24.448-1.984-40
			c-22.976,19.456-42.432,50.528-55.008,83.008C9.28,188.064,1.536,215.616,0,246.016c0,6.976,0,14.016,0,20.992
			c3.904,71.968,33.984,125.632,72,167.008C110.176,475.52,165.792,504.16,236,512c11.36,0,22.688,0,34.016,0
			c28.64-4.48,52.672-17.696,71.008-36.992C360.896,454.048,385.728,414.56,374.016,372z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M264,0c-4.352,0-8.672,0-12.992,0C224.704,4.416,199.04,11.936,184,29.024C173.44,40.992,165.12,62.304,164,76
			c-4.736,58.848,37.44,80.256,76.992,101.024c40.992,21.472,83.328,43.296,125.024,64.992c21.472,11.2,41.024,22.752,56,39.008
			c27.264,29.568,44.288,68.096,40.992,124c6.752-6.528,12.864-15.648,17.984-24.992c14.88-27.2,26.912-61.856,30.016-102.016
			c-0.16-1.184,0.192-1.792,0.992-2.016c0-13.344,0-26.656,0-40C494.4,95.328,398.656,10.848,264,0z"
          />
        </g>
      </g>
    </MySvgIcon>
  );
};

export const GetIconByName = (name: string) => {
  switch (name) {
    case "security":
      return <Security />;
    case "simple":
      return <Simple />;
    case "devices":
      return <Devices />;
    default:
      return <></>;
  }
};
