import React from "react";
import { Follow } from "../components/atoms/Follow";
import { ContentBlock } from "../components/ContentBlock";
import { GetIconByName } from "../components/Images";
import i18n from "../config/locale";

export const MainPage = () => {
  const texts = i18n.to<{ title: string; description: string; icon: string }[]>(
    "texts"
  );
  return (
    <Follow>
      {texts.map((one, i) => {
        const direction = i % 2 === 0 ? "left" : "right";
        return (
          <ContentBlock
            key={one.title}
            icon={GetIconByName(one.icon)}
            title={one.title}
            description={one.description}
            slideDirection={direction}
          />
        );
      })}
    </Follow>
  );
};
