const de = {
  name: "PWD Crypt",
  links: {
    android: "https://play.google.com/store/apps/details?id=com.pwdapp",
  },
  texts: [
    {
      icon: "simple",
      title: "Einfache Bedienung",
      description:
        "Der Focus liegt auf schneller und einfachem Anlegen und verwenden deiner Passwörter",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "devices",
      title: "Synchronisieren über all deine Geräte",
      description:
        "Erhalte zugriff auf deine Passwörter über verschiedene Geräte ohne Sicherheitsverlust",
    },
  ],
  dataPrivacy: {
    title: "Datenschutzerklärung",
    data: [
      {
        title: "Grundlegendes",
        text: `Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und
          den Zweck der Erhebung und Verwendung personenbezogener Daten durch den
          Websitebetreiber fasibio.de Fabian Simon Email: fasibio@gmail.com informieren.
          
          Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre
          personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da
          durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen
          an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die
          Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
          
          Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”)
          finden Sie in Art. 4 DSGVO.`,
      },
      {
        title: "Datenschutz",
        text: `Wir haben diese Datenschutzerklärung (Fassung 18.03.2019-311098355) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
        Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.`,
      },
      {
        title: "Automatische Datenspeicherung",
        text: `Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.

        Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie
        
        die Adresse (URL) der aufgerufenen Webseite
        Browser und Browserversion
        das verwendete Betriebssystem
        die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
        den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
        Datum und Uhrzeit
        in Dateien (Webserver-Logfiles).
        
        In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 f DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass berechtigtes Interesse daran besteht, den fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu ermöglichen.`,
      },
      {
        title: "Speicherung persönlicher Daten",
        text: `Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.

        Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        
        Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
        
        Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.`,
      },
      {
        title: "Rechte laut Datenschutzgrundverordnung",
        text: `Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:

        Recht auf Berichtung (Artikel 16 DSGVO)
        Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
        Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
        Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
        Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
        Widerspruchsrecht (Artikel 21 DSGVO)
        Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.`,
      },
      {
        title: "TLS-Verschlüsselung mit https",
        text: `Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.`,
      },
      {
        title: "Cookies",
        text: `Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.
        Ein Cookie ist ein kurzes Datenpaket, welches zwischen Webbrowser und Webserver ausgetauscht wird, für diese aber völlig bedeutungslos ist und erst für die Webanwendung, z. B. einen Online-Shop, eine Bedeutung erhält, etwa den Inhalt eines virtuellen Warenkorbes.
        
        Es gibt zwei Arten von Cookies: Erstanbieter-Cookies werden von unserer Website erstellt, Drittanbieter-Cookies werden von anderen Websites (z. B. Google Analytics) erstellt.
        Beispielhafte Cookiedaten:
        
        Name: \\_ga
        Ablaufzeit: 2 Jahre
        Verwendung: Unterscheidung der Webseitenbesucher
        Beispielhafter Wert: GA1.2.1326744211.152311098355
        Man unterscheidet drei Kategorien von Cookies: unbedingt notwendige Cookies um grundlegende Funktionen der Website sicherzustellen, funktionelle Cookies um die Leistung der Webseite sicherzustellen und zielorientierte Cookies um das Benutzererlebnis zu verbessern.
        
        Wir nutzen Cookies, um unsere Webseite nutzerfreundlicher zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.`,
      },
      {
        title: "Cookie Einstellungen sehen und Cookies löschen",
        text: `Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, Cookie-Einstellungen ändern oder Cookies löschen möchten, können Sie dies in Ihren Browser-Einstellungen finden:

        Safari: Verwalten von Cookies und Websitedaten mit Safari
        Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
        Chrome: Cookies in Chrome löschen, aktivieren und verwalten
        Internet Explorer: Löschen und Verwalten von Cookies
        Wenn Sie die Speicherung von Daten in Cookies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Sie können jederzeit Cookies, die sich bereits auf Ihrem Computer befinden, löschen oder Cookies deaktivieren. Die Vorgangsweise dazu ist nach Browser unterschiedlich, am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers oder tauschen das Wort “Chrome” gegen den Namen Ihres Browsers, z. B. Edge, Firefox, Safari aus.
        Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h. diese per Browsereinstellung deaktivieren, können manche Funktionen und Seiten nicht wie erwartet funktionieren.`,
      },
      {
        title: "Google Fonts Datenschutzerklärung",
        text: `Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. Die Verwendung von Google Fonts erfolgt ohne Authentisierung und es werden keine Cookies and die Google Fonts API gesendet. Sollten Sie ein Konto bei Google haben, werden keine Ihrer Google-Kontodaten an Google während der Nutzung von Google Fonts übermittelt. Google erfasst lediglich die Nutzung von CSS und der verwendeten Fonts und speichert diese Daten sicher. Mehr zu diesen und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=311098355.

        Welche Daten von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
        
        Quelle: Erstellt mit dem Datenschutz Generator von adsimple.de in Kooperation mit holidao.de`,
      },
    ],
  },
  agb: {
    title: "Allgemeine Geschäftsbedingungen",
    data: [
      {
        title: "Allgemeines",
        text:
          'PwdCrypt ist ein Sicherheitssoftware Programm für Mobilgeräte und Desktoppersonen. Es erlaubt das Speichern von Passwörtern und sicheren Daten und eine synchronisierung Ihre Daten auf höchster, derzeit bekanntem Sicherheitslevel. PwdCrypt wird m vorliegenden Dokument als "PwdCrypt", ",Pwd","System " oder "Software" bezeichnet. Im Folgenden werden die allgemeinen Geschäftsbedingungen für Ihr Abonnement oder Nutzung von PwdCrypt und Ihre Nutzung der Software dargelegt. Mit der Nutzung der Software erklären Sie sich mit diesen Bestimmungen einverstanden. Diese Nutzungsbedingungen sind nicht auf andere übertragbar.',
      },
      {
        title: "Beschränkungen & Verantwortlichkeiten",
        text:
          "Indem Sie die Software nutzen, willigen Sie ein: I: Kein Material zu Speichern die, die Funktionalität der Software stören, zerstören, unkenntlich machen oder begrenzen kann. II: Kein Material hochzuladen, das illegal, pornographisch, missbräuchlich oder obzön ist. III: Kein Material hochzuladen das den Datenschutz oder die Rechte geistigen Eigentums anderer verletzt. IIII: Keinen Teil der Software zurück zu entwickeln (“Reverse Engineering”), zu verändern, zu hacken, übersetzen, kopieren, vertreiben, verpfänden, übereignen, übertragen, abzuleiten, nutzen, auszulasten oder zu knacken. V: Wenn Sie kein autorisierter Händler der Software sind, ist es Ihnen darüber hinaus untersagt, die Software, Bestandteile davon, die zugehörige Dokumentation oder Inhalte davon vorzuführen, zu kopieren, zu reproduzieren oder zu vertreiben. VI: PwdCrpyt kann nach eigenem Ermessen Inhalte entfernen und den Zugriff von Benutzern auf jedwede Inhalte sperren, die als illegal, bedrohlich, pornographisch, missbräuchlich, obszön oder als Verstoß gegen das geistige Eigentum Dritter erachtet werden.",
      },
      {
        title: "Master-Passwort",
        text:
          "Das Masterpasswort dient ausschließlich zum schutz vor unerlaubten zugriff auf die Application und wird nicht an die PwdCrypt-Cloud übertragen. Somit sollten Sie pro Gerät ein anderes Masterpasswort zum Schutz vor unberechtigtem Zugang auf Ihren Endgeräten verwenden. Sie sind für die Wahrung der Sicherheit Ihrer Master-Passworts und die über die Software eingegebenen verantwortlich. Pwd Crypt hat unter keinen Umständen Zugang auf Ihr Passwort oder verschlüsselten Datensätze, die Sie in PwdCrypt speichern. Bitte merken Sie sich Ihre Master-Passwörter. Für den Fall, dass Sie Ihr Master-Passwort verlieren oder vergessen, kann Ihnen PwdCrypt keine Möglichkeit zur Wiedererlangung des Zugriffs auf Ihre Datensätze bieten",
      },
      {
        title: "Software und Support",
        text:
          'PwdCrypt erfordert eine Internetverbindung für den Betrieb um Daten zu synchronisieren und den einwandfreien Betrieb zu gewährleisten. Die von Ihnen in PwdCrypt erstellten Daten werden über eine 256-Bit-verschlüsselte Secure Socket Layer (SSL/HTTPS)-Verbindung in der PwdCrypt Cloud übertragen. Ihre Daten sind über elliptic curve P521 verschlüsselt. Die Privaten Schlüssel, Ihrer registrierten Endgeräte verbleibt ausschließlich auf diesen, und werden nicht übertragen. Mitarbeiter von PwdCrypt haben keinen Zugriff auf Ihre Datensätze. Wenn Sie all Ihre Geräte (auf denen die Privaten Schlüssel gespeichert sind ) und Sie ihr lokales Masterpasswort vergessen haben, können wir nichts unternehmen, damit Sie Ihre Daten wiedererlangen. Informationen wie Ihre IP-Adresse und Login-Daten werden zu Ihrer Sicherheit und zu Ihrem Schutz verwendet. Die bereitgestellte Software unterliegt den Bestimmungen der vorliegenden Vereinbarung, die von PwdCrypt abgeändert werden kann, sowie allen Richtlinien, Regeln und Betriebsleitsätzen, die PwdCrypt von Zeit zu Zeit festlegen und veröffentlichen kann (die vorliegende "Agreement" einschließlich der Datenschutzrichtlinie von PwdCrypt auf die in allen von PwdCrypt generierten oder anderweitig an Sie versendeten E-Mails verwiesen wird (die "Richtlinie") – sofern nicht ausdrücklich anders festgelegt, beziehen sich alle Verweise auf die Vereinbarung auch auf die Richtlinien).',
      },
      {
        title: "Abonnementgebühren",
        text:
          "Die durch PwdCrypt eingehobenen Entgelte verstehen sich als Abonnementgebühren. Die Kostenpflichtige version unterliegt einem monatlichem Abonnementgebühr zur Nutzung der Software auf mehreren Mobilgeräten oder Computern, Synchronisierung von Datensätzen auf mehreren Geräten von Datensätzen und Dateispeicher. Nach dem Upgrade aus der kostenlosen Version von PwdCrypt werden montaliche Abonnementgebühren fällig, die in der Gebührentabelle auf der Website dargestellt sind. Sie sind verpflichtet, die entsprechende Gebühr monatlich im Voraus zu entrichten. Der Zugriff auf die kostenpflichtige Version von PwdCrypt kann gesperrt werden, bis die Zahlung eingegangen ist. Die Abonnementsgebühr wird im Voraus in Rechnung gestellt und eingehoben, wie in Ihrem Abonnement vorgesehen.",
      },
      {
        title: "Gebührenänderungen",
        text:
          "PwdCrypt kann die Gebühren und Entgelte ändern oder neue Gebühren oder Entgelte einführen. In diesem Fall werden Sie im Vorhinein schriftlich verständigt. Alle Gebühren und Entgelte, die im Zusammenhang mit Ihrem Benutzernamen und Passwort entstehen, werden über die Zahlungsmethode in Rechnung gestellt, die Sie bei der Registrierung angegeben haben. Sie tragen die Verantwortung für alle Gebühren und Kosten von Telekomanbietern oder anderen Dritten, die Ihnen durch den Zugriff auf die PwdCrypt hinhalte entstehen.",
      },
      {
        title: "Erstattungen",
        text:
          "Sie können Ihr Abonnement jederzeit kündigen. Da die Software für den Zeitraum der Abonnement Monate kostenpflicht ist, erhalten Sie keinerlei Erstattungen.",
      },
      {
        title: "Recht auf Aussetzung des Zugangs",
        text:
          "PwdCrypt ist berechtigt, Ihren Zugang zur Software nach eigenem Ermessen und ohne Anspruch auf Rückerstattung mit sofortiger Wirkung auszusetzen, wenn Grund zur Annahme besteht, dass Sie gegen jedwede obere oder in dieser Vereinbarung genannte Bestimmungen verstoßen haben.",
      },
      // { title: "Ungültige oder abgebrochene Zahlungen", text: "" },
      {
        title: "Haftungsfreistellung",
        text:
          "Sie erklären hiermit, Pwd Crypt sowie dessen Geschäftspartner, externe Lieferanten, Zulieferer, Lizenzgeber, leitende Angestellte, Geschäftsführer, Mitarbeiter, Händler und Vertreter von allen Schadenersatzforderungen, Verlusten, Haftungen, Regulierungen und Kosten (einschließlich angemessener Anwaltskosten) im Zusammenhang mit Forderungen oder Klagen freizustellen, die (i) aus einem mutmaßlichem Verstoß gegen die vorliegende Vereinbarung, (ii) aus dem Inhalt oder den Folgen jedweder Nachrichten, die Sie über die Software versenden, oder (iii) aus und im Zusammenhang mit Ihrer Nutzung der Software entstehen. Darüber hinaus anerkennen Sie, dass Pwd Crypt berechtigt ist, Schadenersatz von Ihnen einzufordern, wenn Sie die Software für gesetzeswidrige Zwecke oder in einer Weise nutzen, die gegen die vorliegende Vereinbarung verstößt, und dass dieser Schadenersatz unter anderem auf direkte, indirekte, spezielle, zufällige, Deckungs-, Vertrauens- und Folgeschäden anwendbar ist.",
      },
    ],
  },
  footer: {
    data: [
      {
        title: "PWD Crypt",
        items: [
          {
            title: "Startseite",
            link: "/",
          },
          {
            title: "AGB",
            link: "/agb",
          },
          {
            title: "Datenschutzerklärung",
            link: "/privacy",
          },
        ],
      },
      {
        title: "Downloads",
        items: [
          {
            title: "Android",
            link: "/android",
          },
        ],
      },
    ],
  },
};
export default de;
