export interface Event {
  action: string;
}

type LogData = unknown;

export const sendTrackEvent = (data: LogData) => {
  fetch("https://tracking.fasibio.de/tracking", {
    method: "POST",
    body: JSON.stringify(data),
  });
};
