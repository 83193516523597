import React from "react";
import styled from "styled-components";
import { Slide } from "react-reveal";
import { useHistory } from "react-router-dom";
import { Flex1 } from "./atoms/Flex";
import { H1 } from "./atoms/Text";

const MyImg = styled.div`
  width: 192px;
  height: 192px;
  background-image: url("assets/ic_launcher_round.png");
  background-size: cover;
  cursor: pointer;
`;

const Root = styled.div`
  display: flex;
`;

const SubRoot = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Header = () => {
  const history = useHistory();
  return (
    <Root>
      <Flex1 />
      <SubRoot>
        <Slide left big>
          <MyImg
            onClick={() => {
              history.push("/");
            }}
          />
        </Slide>
        <Slide right big>
          <H1>PWD Crypt</H1>
        </Slide>
      </SubRoot>
      <Flex1 />
    </Root>
  );
};
