/* eslint-disable */
import i18next from "i18next";
import * as detectBrowserLanguage from "browser-locale";
import en from "./locations/en";
import de from "./locations/de";

type LanObj = {
  [key: string]: any;
};
export const lanObj: LanObj = {
  de,
  en,
};

interface Resources {
  [key: string]: {
    translation: Object;
  };
}

const resources: Resources = {};
for (const one in lanObj) {
  resources[one] = {
    translation: lanObj[one],
  };
}

let clientLan = detectBrowserLanguage().split("-")[0];
let hasLan = false;
for (const one in lanObj) {
  if (one === clientLan) {
    hasLan = true;
  }
}
if (!hasLan) {
  clientLan = "en";
}
console.log("da!!! ",clientLan)

i18next.init({
  resources,
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  // Using simple hardcoded resources for simple example
});

const i18nT = {
  t: (t: string, value?: { [key: string]: any }) =>
    i18next.t(t, { lng: clientLan, ...value }),
  to: <K>(t: string): K => {
    const res = (i18next.t(t, {
      returnObjects: true,
      lng: clientLan
    }) as any) as K
    return res
  },
};
export { i18next };
export default i18nT;
