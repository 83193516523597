import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import track from "react-tracking";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendTrackEvent } from "./utils";

const TrackedApp = track(
  {
    app: "pwdwebsite",
    pagelocation: window.location.href,
  },
  {
    dispatch: (data) => {
      sendTrackEvent(data);
    },
  }
)(App);

ReactDOM.render(
  <React.StrictMode>
    <TrackedApp />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
