const en = {
  name: "PWD Crypt",
  texts: [
    {
      icon: "",
      title: "Simple Useage",
      description:
        "Der Focus liegt auf schneller und einfachem Anlegen und verwenden deiner Passwörter",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
    {
      icon: "security",
      title: "Hohe Sicherheit",
      description:
        "Mit Eliptic Curve beruht die Verschlüssung auf der selben Technologie wie Online Crypto Währung ",
    },
  ],
};
export default en;
