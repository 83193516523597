import styled from "styled-components";

interface FlexProps {
  disableAtMobile?: boolean;
}

export const Flex1 = styled.div<FlexProps>`
  flex: 1;
  @media screen and (max-width: 500px) {
    ${(p) => (p.disableAtMobile ? "flex: 0" : "")}
  }
`;

export const Flex01 = styled.div<FlexProps>`
  flex: 0.1;
`;
