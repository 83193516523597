import React from "react";
import WbSunnyOutlinedIcon from "@material-ui/icons/WbSunnyOutlined";
import Brightness2OutlinedIcon from "@material-ui/icons/Brightness2Outlined";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import I18n from "../../config/locale";

interface Props {
  showSun: boolean;
  onClick: (showDark: boolean) => void;
}

const Sun = styled(WbSunnyOutlinedIcon)`
  && {
    cursor: pointer;
    color: #ffffff;
    :hover {
      color: #ed7d31;
    }
    @media print {
      display: none;
    }
  }
`;

const Moon = styled(Brightness2OutlinedIcon)`
  && {
    cursor: pointer;
    :hover {
      color: #ed7d31;
    }
    @media print {
      display: none;
    }
  }
`;

const MyTooltip = styled(Tooltip)`
  padding: 6px;
`;

export const ToggleLight = ({ showSun, onClick }: Props) => {
  if (showSun) {
    return (
      <MyTooltip title={I18n.t("toogleLight_sun")}>
        <Sun
          onClick={() => {
            onClick(false);
          }}
        />
      </MyTooltip>
    );
  }
  return (
    <MyTooltip title={I18n.t("toogleLight_moon")}>
      <Moon
        onClick={() => {
          onClick(true);
        }}
      />
    </MyTooltip>
  );
};
